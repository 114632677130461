
import { deepMerge } from 'grommet/utils';

export const customTheme = deepMerge({
    global: {
        colors: {
            primary: '#BDB3FF',
            primaryDark: '#8d92b3',
            accent: '#fc839d',
            dark: '#444444',
            light: '#F6F5FF',
            focus: '#8d92b3' // added focus color
        },
        font: {
            family: 'Roboto',
            size: '18px',
            height: '20px',
        },
        breakpoints: {
            xsmall: { value: 500 },
            small: { value: 900 },
            medium: { value: 1500 },
            middle: { value: 3000 },
        },
    }
});